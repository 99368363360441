import React from 'react';
import { useParams } from 'react-router-dom';
import { TextBlock, TypeFblock } from './TypeF.styles';
import { Card } from '../../types';
import { Text } from '../common/Text';
import { Footer } from '../common/Footer';
import { palette } from '../../styles/palette';
import { callFinish } from '../../lib/utils';
import { getContentCompleteAPI } from '../../lib/api/contents';

interface IProps {
  card: Card;
}

function TypeF({ card }: IProps) {
  const { btnLabel, title, content } = card;
  const { contentId } = useParams();

  const handleNavigation = () => {
    getContentCompleteAPI(contentId as string);
    callFinish();
  };

  return (
    <TypeFblock>
      <TextBlock>
        <Text title={title} content={content} />
      </TextBlock>
      <Footer
        btnBgColor={palette.KB_Primary_dark}
        btnOnclick={handleNavigation}
        caption={btnLabel || '완료'}
      />
    </TypeFblock>
  );
}

export default TypeF;
