import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { CompositedType } from '../../../lib/constants';

export const ImageContainer = styled.div<{ cardType?: string }>`
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(0, 0, 0, 0.2) 25%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.4) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );

  ${({ cardType }) =>
    cardType === CompositedType.B &&
    css`
      background: none;
    `};
`;

export const IMG = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
`;
