import { useQuery } from 'react-query';
import { QueryKey } from '../lib/queryKey';
import { getContentDetailAPI } from '../lib/api/contents';

export const useGetContentDetail = (id: string) => {
  return useQuery(
    [QueryKey.GET_CONTENT_DETAIL, id],
    () => getContentDetailAPI(id),
    {
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  );
};
