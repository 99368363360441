import React, { useEffect, useRef } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { Card } from '../../types';
import { Image } from '../common/Image';
import { TypeBblock } from './TypeB.styles';

interface IProps {
  card: Card;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
}

// 이미지
function TypeB({ card, setIsScrolling }: IProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { isActive } = useSwiperSlide();

  useEffect(() => {
    if (!isActive) {
      const target = ref.current;
      if (target) {
        setIsScrolling(false);
        setTimeout(() => {
          target.scrollTo(0, 0);
        }, 300);
      }
    }
  }, [isActive]);

  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.addEventListener('scroll', () => {
        if (ref.current!.scrollTop > 10) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      });
    }
  }, []);
  return (
    <TypeBblock ref={ref}>
      <Image url={card.imgUrl} cardType={card.compositedType} />
    </TypeBblock>
  );
}

export default TypeB;
