import React from 'react';
import parse from 'html-react-parser';
import { Content, TextBlock, Title } from './Text.styles';
import { handleReplace } from '../../../lib/utils';

interface ITextProps {
  title?: string;
  content?: string;
}

function Text({ title, content }: ITextProps) {
  const titleFormat = title?.replace(/\n|::b|b::|::c|c::/g, handleReplace);
  const contentFormat = content?.replace(/\n|::b|b::|::c|c::/g, handleReplace);

  return (
    <TextBlock>
      {title && <Title>{parse(titleFormat as string)}</Title>}
      <Content>{content && parse(contentFormat as string)}</Content>
    </TextBlock>
  );
}

export default Text;
