import React from 'react';
import { Block, HeaderTitle, CloseButton } from './Header.styles';
import Close from '../../../assets/svg/Close';
import { callFinish } from '../../../lib/utils';

interface IHeaderProps {
  backbtnColor?: string;
  isScroll?: boolean;
}

function Header({ backbtnColor, isScroll }: IHeaderProps) {
  const handleFinish = () => {
    callFinish();
  };

  return (
    <Block isScroll={isScroll}>
      <HeaderTitle isScroll={isScroll}>
        <b>오늘의 건강정보</b>
      </HeaderTitle>
      <CloseButton onClick={handleFinish}>
        <Close color={backbtnColor} isScroll={isScroll} />
      </CloseButton>
    </Block>
  );
}

export default Header;
