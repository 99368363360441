import React from 'react';
import { Announcement, Block, CopyRight } from './Footer.styles';
import { Button } from '../Button';

type FooterProps = {
  announcement?: boolean;
  copyright?: boolean;
  btnOnclick: () => void;
  btnBgColor: string;
  caption: string | undefined;
};

function Footer({
  announcement,
  copyright,
  btnOnclick,
  caption,
  btnBgColor,
}: FooterProps) {
  return (
    <Block>
      {/* TODO: 추후 서버 데이터 description으로 교체할수도. 확인 필요 */}
      {announcement && (
        <Announcement>
          건강정보는 객관적이고 일반적인 내용을 바탕으로 작성되었습니다.
          <br />
          건강상태에 따른 구체적인 내용은 반드시 의사와 상담하시기 바랍니다.
        </Announcement>
      )}
      <Button btnBgColor={btnBgColor} onClick={btnOnclick} caption={caption} />
      {copyright && (
        <CopyRight>
          Copyright ⓒ Huraypositive Corp. All rights reserved.
        </CopyRight>
      )}
    </Block>
  );
}

export default Footer;
