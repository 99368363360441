import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import { ReactQueryKey } from '../../lib/queryKey';
import { Card, CardContentResponse } from '../../types';
import TypeA from '../../components/TypeA/TypeA';
import TypeB from '../../components/TypeB/TypeB';
import TypeC from '../../components/TypeC/TypeC';
import TypeD from '../../components/TypeD/TypeD';
import TypeE from '../../components/TypeE/TypeE';
import TypeF from '../../components/TypeF/TypeF';
import { CompositedType } from '../../lib/constants';
import { ContentBlock } from './ContentsPage.styles';
import { Header } from '../../components/common/Header';
import { handleHeaderColor } from '../../lib/utils';

const handleContentPage = (
  card: Card,
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  switch (card.compositedType) {
    case CompositedType.A:
      return <TypeA card={card} setIsScrolling={setIsScrolling} />;
    case CompositedType.B:
      return <TypeB card={card} setIsScrolling={setIsScrolling} />;
    case CompositedType.C:
      return <TypeC card={card} setIsScrolling={setIsScrolling} />;
    case CompositedType.D:
      return <TypeD card={card} setIsScrolling={setIsScrolling} />;
    case CompositedType.E:
      return <TypeE card={card} setIsScrolling={setIsScrolling} />;
    case CompositedType.F:
      return <TypeF card={card} />;
    default:
      return null;
  }
};

function ContentsPage() {
  const { contentId } = useParams();
  const queryClient = useQueryClient();
  const data = queryClient.getQueryData<CardContentResponse>(
    ReactQueryKey.contents(contentId as string),
  );
  const [backbtnColor, setBackbtnColor] = useState<string>();
  const [isScrolling, setIsScrolling] = useState(false);
  const [cardListData, setCardListData] = useState<Card[] | null>(null);

  useEffect(() => {
    if (data) {
      setCardListData(data.cards);
    }
  }, [data]);

  if (!data) {
    return <div>Loading...</div>;
  }

  if (!cardListData) {
    return null;
  }

  return (
    <ContentBlock className="contentBlock">
      <Header backbtnColor={backbtnColor} isScroll={isScrolling} />
      <Swiper
        pagination
        modules={[Pagination]}
        resistanceRatio={0}
        className="mySwiper"
        id="swiper-wrapper"
        style={{ height: '100%' }}
        onRealIndexChange={(swiper) => {
          const currentCardIndex = swiper.realIndex;
          // 페이지 타입에 따른 헤더 컬러 변경
          setBackbtnColor(
            handleHeaderColor(cardListData[currentCardIndex]?.compositedType),
          );
        }}
      >
        {data.cards.map((card, index) => (
          <SwiperSlide key={index}>
            {handleContentPage(card, setIsScrolling)}
          </SwiperSlide>
        ))}
      </Swiper>
    </ContentBlock>
  );
}

export default ContentsPage;
