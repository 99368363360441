import { palette } from '../styles/palette';

export const handleReplace = (match: string) => {
  switch (match) {
    case '\n':
      return '<br />';
    case '::b':
      return '<b>';
    case 'b::':
      return '</b>';
    case '::c':
      return '<span style="color: #FFD300;">';
    case 'c::':
      return '</span>';
    default:
      return match;
  }
};

export const handleHeaderColor = (pageType: string) => {
  switch (pageType) {
    case 'A':
      return palette.Black;
    case 'B':
      return palette.Black;
    case 'E':
      return palette.Black;
    default:
      return palette.White;
  }
};

// 현재 호출한 디바이스가 어떤 것인지 체크합니다.
const isMobile = {
  Android() {
    return navigator.userAgent.match(/Android/i) != null;
  },
  iOS() {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i) != null;
  },
  any() {
    return isMobile.Android() || isMobile.iOS();
  },
};

// 웹뷰 종료를 알리는 함수입니다.
export const callFinish = () => {
  if (isMobile.any()) {
    if (isMobile.Android()) {
      /* global Android */
      /* eslint no-undef: "error" */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      Android.callFinish(JSON.stringify({}));
    } else if (isMobile.iOS()) {
      /* global webkit */
      /* eslint no-undef: "error" */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
      webkit.messageHandlers.callFinish.postMessage({});
    }
  }
};
