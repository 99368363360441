import styled from '@emotion/styled';
import { palette } from '../../styles/palette';

export const TypeFblock = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: ${palette.KB_Primary};
  z-index: 1000;

  padding-top: 5.6rem;
`;

export const TextBlock = styled.div`
  flex: 1;
  overflow: auto;
`;
