import React from 'react';
import { ImageContainer, IMG } from './Image.styles';

type ImageProps = {
  url: string | undefined;
  cardType?: string;
};

function Image({ url, cardType }: ImageProps) {
  return (
    <ImageContainer cardType={cardType}>
      <IMG src={url} alt="이미지" />
    </ImageContainer>
  );
}

export default Image;
