import styled from '@emotion/styled';
import { palette } from '../../../styles/palette';

export const StyledButton = styled.button<{ btnBgColor: string }>`
  width: 100%;
  min-height: 5.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1.2rem;
  font-size: 1.8rem;
  background-color: ${({ btnBgColor }) => btnBgColor};
  color: ${palette.White};
  padding: 0 2.4rem;
  margin-top: 1rem;
`;
