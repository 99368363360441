import { useLocation, Navigate } from 'react-router-dom';
import qs from 'qs';

function FallBack() {
  const location = useLocation();
  const qsString = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  });
  const id = qsString?.id as string;
  // 실서버에서 card는 KB큐레이션, post는 하이맘으로 서비스
  if (
    (location.pathname === '/' ||
      location.pathname === '/card/' ||
      location.pathname === '/card') &&
    id
  ) {
    return <Navigate to={`/contents/${id}`} />;
  }
  return <div>Error Page</div>;
}

export default FallBack;
