import styled from '@emotion/styled';

export const TypeCblock = styled.div`
  height: calc(100% - 6.8rem);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const TextBlock = styled.div`
  flex: 1;
`;
