import styled from '@emotion/styled';

export const TypeBblock = styled.div`
  height: calc(100% - 6.8rem);
  display: flex;
  flex-direction: column;

  overflow: auto;

  padding-top: 5.6rem;
`;
