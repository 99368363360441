import React from 'react';

const handleColor = (
  color: string | undefined,
  isScroll: boolean | undefined,
) => {
  if (isScroll) {
    return '#444444';
  }
  if (color) {
    return color;
  }

  return '#fff';
};

function Close({ color, isScroll }: { color?: string; isScroll?: boolean }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m19.63 4.205.09.082c.34.348.375.9.08 1.29l-.08.092L13.525 12l6.194 6.33c.354.365.371.949.038 1.335a.943.943 0 0 1-1.301.13l-.09-.082L12 13.21l-6.365 6.504a.943.943 0 0 1-1.264.082l-.09-.082a.994.994 0 0 1-.08-1.291l.08-.091L10.476 12 4.282 5.67a.994.994 0 0 1-.031-1.328.943.943 0 0 1 1.292-.137l.09.082L12 10.79l6.365-6.504a.943.943 0 0 1 1.264-.082h.002z"
        fillRule="nonzero"
        fill={handleColor(color, isScroll)}
        stroke={handleColor(color, isScroll)}
      />
    </svg>
  );
}

export default Close;
