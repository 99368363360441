import { Route, Routes } from 'react-router-dom';
import Path from './Path';
import FallBack from './Fallback';
import { ContentsPage } from '../pages/ContentsPage';
import { CoverPage } from '../pages/CoverPage';

const location = new URL(document.location.href);
const { pathname } = location;
const queryParams = location.searchParams;
const token = queryParams.get('t');
if ((pathname === '/' || pathname === '/card/') && token)
  sessionStorage.setItem('token', decodeURIComponent(token));

export default (
  <Routes>
    <Route path={Path.CoverPage} element={<CoverPage />} />
    <Route path={Path.ContentsPage} element={<ContentsPage />} />
    <Route path="*" element={<FallBack />} />
  </Routes>
);
