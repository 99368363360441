import styled from '@emotion/styled';

export const TypeDblock = styled.div`
  height: calc(100% - 6.8rem);
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const ImageBlock = styled.div`
  width: 100%;
  height: 21rem;
  position: relative;
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 21rem;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.1) 10%,
    rgba(0, 0, 0, 0.2) 25%,
    rgba(0, 0, 0, 0.3) 50%,
    rgba(0, 0, 0, 0.4) 75%,
    rgba(0, 0, 0, 0.5) 100%
  );
`;
