import React, { useEffect, useRef } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { Card } from '../../types';
import { Text } from '../common/Text';
import { TextBlock, TypeAblock } from './TypeA.styles';

interface IProps {
  card: Card;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
}

// 내용
function TypeA({ card, setIsScrolling }: IProps) {
  const { title, content } = card;
  const ref = useRef<HTMLDivElement>(null);
  const { isActive } = useSwiperSlide();

  useEffect(() => {
    if (!isActive) {
      const target = ref.current;
      if (target) {
        setIsScrolling(false);
        setTimeout(() => {
          target.scrollTo(0, 0);
        }, 300);
      }
    }
  }, [isActive]);

  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.addEventListener('scroll', () => {
        if (ref.current!.scrollTop > 10) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      });
    }
  }, []);

  return (
    <TypeAblock ref={ref}>
      <TextBlock>
        <Text title={title} content={content} />
      </TextBlock>
    </TypeAblock>
  );
}

export default TypeA;
