import React, { useEffect, useRef } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { Card } from '../../types';
import { TypeEblock } from './TypeE.styles';
import { Text } from '../common/Text';

interface IProps {
  card: Card;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
}

// 제목 내용
function TypeE({ card, setIsScrolling }: IProps) {
  const { title, content } = card;
  const ref = useRef<HTMLDivElement>(null);
  const { isActive } = useSwiperSlide();

  useEffect(() => {
    if (!isActive) {
      const target = ref.current;
      if (target) {
        setIsScrolling(false);
        setTimeout(() => {
          target.scrollTo(0, 0);
        }, 300);
      }
    }
  }, [isActive]);

  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.addEventListener('scroll', () => {
        if (ref.current!.scrollTop > 10) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      });
    }
  }, []);

  return (
    <TypeEblock ref={ref}>
      <Text title={title} content={content} />
    </TypeEblock>
  );
}

export default TypeE;
