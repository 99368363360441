import React, { useEffect, useRef } from 'react';
import { useSwiperSlide } from 'swiper/react';
import { Card } from '../../types';
import { Text } from '../common/Text';
import { TypeDblock } from './TypeD.styles';
import { Image } from '../common/Image';

interface IProps {
  card: Card;
  setIsScrolling: React.Dispatch<React.SetStateAction<boolean>>;
}

// 제목 내용 이미지
function TypeD({ card, setIsScrolling }: IProps) {
  const { title, content, imgUrl } = card;
  const ref = useRef<HTMLDivElement>(null);
  const { isActive } = useSwiperSlide();

  useEffect(() => {
    if (!isActive) {
      const target = ref.current;
      if (target) {
        setIsScrolling(false);
        setTimeout(() => {
          target.scrollTo(0, 0);
        }, 300);
      }
    }
  }, [isActive]);

  useEffect(() => {
    const target = ref.current;
    if (target) {
      target.addEventListener('scroll', () => {
        if (ref.current!.scrollTop > 10) {
          setIsScrolling(true);
        } else {
          setIsScrolling(false);
        }
      });
    }
  }, []);

  return (
    <TypeDblock ref={ref}>
      <Image url={imgUrl} />
      <Text title={title} content={content} />
    </TypeDblock>
  );
}

export default TypeD;
