import styled from '@emotion/styled';

export const TextBlock = styled.div`
  padding: 2.4rem 2.4rem 0 2.4rem;
`;

export const Title = styled.h1`
  word-break: keep-all;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
`;

export const Content = styled.p`
  word-break: keep-all;
  font-size: 1.8rem;
`;
