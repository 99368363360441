import { client } from './index';
import { CardContentResponse } from '../../types';

export const getContentDetailAPI = async (id: string) => {
  const token = sessionStorage.getItem('token') as string;
  const response = await client.get<{
    data: CardContentResponse;
  }>(
    `contents/${id}`,
    token
      ? {
          params: null,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : undefined,
  );
  return response.data.data;
};

export const getContentCompleteAPI = async (id: string) => {
  const token = sessionStorage.getItem('token') as string;
  client.patch<{
    data: CardContentResponse;
  }>(
    `contents/${id}/complete`,
    null,
    token
      ? {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      : undefined,
  );
};
