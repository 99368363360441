import styled from '@emotion/styled';
import { css } from '@emotion/react';

export const Block = styled.div<{ isScroll?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 5.6rem;
  background-color: transparent;
  padding: 1.9rem 2.3rem;
  position: sticky;
  top: 0;
  z-index: 999;

  position: absolute;

  ${({ isScroll }) =>
    isScroll &&
    css`
      background-color: #fff;
    `}
`;

export const HeaderTitle = styled.div<{ isScroll?: boolean }>`
  font-family: 'Spoqa Han Sans Neo';
  align-items: left;
  text-weight: bold;
  color: transparent;
  font-size: 1.6rem;

  ${({ isScroll }) =>
    isScroll &&
    css`
      color: #000000 !important;
    `}
`;

export const CloseButton = styled.button``;
