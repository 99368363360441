import styled from '@emotion/styled';

export const Block = styled.div`
  padding: 2.4rem;
`;

export const Announcement = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
`;

export const CopyRight = styled.p`
  color: rgba(255, 255, 255, 0.7);
  font-size: 1rem;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;
`;
