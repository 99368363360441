import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import parse from 'html-react-parser';
import { useGetContentDetail } from '../../services/ContentQuery';
import { Container, CoverBlock, Title } from './CoverPage.styles';
import { handleReplace } from '../../lib/utils';
import { Footer } from '../../components/common/Footer';
import { palette } from '../../styles/palette';
import { Header } from '../../components/common/Header';

function CoverPage() {
  const { contentId } = useParams();
  const { data } = useGetContentDetail(contentId as string);
  const navigate = useNavigate();

  if (!data) {
    return <div>Loading...</div>;
  }
  const { title, bgImgUrl } = data;

  const TitleFormat = title.replace(/\n|::b|b::|::c|c::/g, handleReplace);

  const handleNavigation = () => {
    navigate(`../contents/main/${contentId as string}`);
  };

  return (
    <Container bgImgUrl={bgImgUrl}>
      <CoverBlock>
        <Header />
        <Title>{parse(TitleFormat)}</Title>
        <Footer
          btnBgColor={palette.KB_Primary}
          btnOnclick={handleNavigation}
          announcement
          copyright
          caption="시작하기"
        />
      </CoverBlock>
    </Container>
  );
}

export default CoverPage;
