import React from 'react';
import { StyledButton } from './Button.styles';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  caption: string | undefined;
  btnBgColor: string;
}

function Button({ caption, btnBgColor, ...rest }: ButtonProps) {
  return (
    <StyledButton btnBgColor={btnBgColor} {...rest}>
      {caption}
    </StyledButton>
  );
}

export default Button;
