import styled from '@emotion/styled';
import { palette } from '../../styles/palette';

export const Container = styled.div<{ bgImgUrl: string }>`
  position: relative;
  background: url(${(props) => props.bgImgUrl});
  background-size: cover;

  &::before {
    content: '';
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
  }
`;

export const CoverBlock = styled.div`
  position: relative;
  height: 100vh;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  flex: 1;
  font-size: 3rem;
  color: ${palette.White};
  padding: 5.6rem 2.4rem 0 2.4rem;
  overflow: auto;
`;
